<template lang="pug">
head-meta(:title-page="titlePage", :description="descriptionPage")
search-cap(
    v-if="!isNotFound"
    :query = "query",
    :count = "count",
    :search-in-progress ="searchInProgress"
    :current-page="currentPage"
  )
template(v-if="!searchInProgress")
  template(v-if="!isNotFound")
    filters-line(
      v-if="filters.length"
      :filters-start="filters",
      :sort="sort",
      :products="products",
      :color-count="colorCount"
      @sort-products="sortProducts",
      @apply-filters="applyFilters"
    )
    tile-line(
      :blockName="searchTitle",
      :products="products",
      :current-page = "currentPage",
      :pages="pages",
      :is-loading-button="isLoadingButton"
      @show-more="showMore",
      @update-page="updatePage"
    )
  thanks404(v-else, mod="error")
</template>

<script>
import SearchCap from '../components/search/SearchCap'
import TileLine from '../components/_tiles/TileLine'
import { getProductsCategory, getProductsDesignersChoice, getSearchResults } from '../assets/scripts/Api'
import HeadMeta from '../components/_layout/HeadMeta'
import FiltersLine from '../components/FiltersLine'
import Thanks404 from '../components/Thanks404'

export default {
  name: 'SearchPage',
  components: { Thanks404, FiltersLine, HeadMeta, TileLine, SearchCap },
  data () {
    return {
      products: [],
      count: 0,
      query: '',
      searchInProgress: false,
      filters: [],
      currentPage: 1,
      pages: 1,
      selectedFilters: {},
      sort: '',
      isNotFound: false,
      isLoadingButton: false,
      startProducts: [],
      startPage: 1
    }
  },
  computed: {
    searchTitle () {
      return !this.count ? 'Вам может быть интересно' : ''
    },
    titlePage () {
      if (this.isNotFound) return 'Страница не найдена'
      if (this.query) {
        return this.currentPage > 1 ? `Результаты поиска «${this.query}» - страница ${this.currentPage}` : `Результаты поиска «${this.query}»`
      } else {
        return ''
      }
    },
    descriptionPage () {
      if (this.isNotFound) return 'Проверьте ссылку на страницу, возможно она была удалена или перемещена'
      if (this.query) {
        return this.currentPage > 1 ? `Товары по запросу «${this.query}» - страница ${this.currentPage}` : `Товары по запросу «${this.query}»`
      } else {
        return ''
      }
    },
    colorCount () {
      if (this.filters) {
        const colorFilters = this.filters.find(item => item.slug === 'tsvet')
        if (!colorFilters) return 0
        return colorFilters.list.filter(item => item.is_current).length
      }
      return 0
    }
  },
  methods: {
    async sendRequest (query, page = 1) {
      this.searchInProgress = true
      this.filters = []
      if (query !== this.query) {
        this.sort = ''
        this.selectedFilters = {}
      }
      const data = await getSearchResults(query, page, this.sort, this.selectedFilters)
      this.placeSearchResults(data, query)
      this.searchInProgress = false
    },
    refreshSearchResults (query, page = 1) {
      if (query === undefined) {
        this.showPopularProducts()
      }
      if (query && query.length > 2) {
        this.sendRequest(query, page)
      }
    },
    async showPopularProducts (page = 1) {
      this.isShowFilters = false
      this.searchInProgress = true
      const preloadPopular = this.getPreloadSearchPopular()
      const data = preloadPopular || await getProductsDesignersChoice(page)
      this.searchInProgress = false
      this.placePopular(data)
    },
    applyFilters (filters) {
      this.currentPage = 1
      this.selectedFilters = filters
      this.startProducts = []
      this.refreshSearchResults(this.$route.query.query)
    },
    sortProducts (sort) {
      this.sort = sort
      this.refreshProducts()
    },
    refreshProducts () {
      if (this.currentPage === 1) {
        this.$router.push({ name: this.$route.name, params: { page: '', numberPage: '' }, query: { query: this.$route.query.query, sort: this.sort } })
      } else {
        this.$router.push({ name: this.$route.name, params: { page: 'page', numberPage: this.currentPage }, query: { query: this.$route.query.query, sort: this.sort } })
      }
    },
    isNeedErrorRedirect (to) {
      const page = to.params.page
      const number = to.params.numberPage
      if ((page && !number) || (!page && number)) {
        this.isNotFound = true
        return true
      }
      return false
    },
    getPreloadSearchResults (route, page) {
      if (this.checkPreloadProducts(route)) {
        const parseProducts = this.getParseSearchProducts()
        const pageQuery = route.query.query || ''
        this.placeSearchResults(parseProducts, pageQuery)
      } else {
        this.refreshSearchResults(route.query.query, page)
      }
    },
    getParseSearchProducts () {
      const app = document.querySelector('#app')
      const products = app.dataset.searchProducts
      return products ? JSON.parse(products) : null
    },
    checkPreloadProducts (route) {
      const app = document.querySelector('#app')
      const products = app.dataset.searchProducts
      const parseProducts = products ? JSON.parse(products) : null
      const pageFromRoute = +route.params.numberPage || 1
      const pageQuery = route.query.query || ''
      const sort = route.query.sort || ''
      const isEmptyFilters = !Object.keys(this.selectedFilters).length
      return parseProducts && parseProducts.page === pageFromRoute && parseProducts.sort === sort && parseProducts.query === pageQuery && isEmptyFilters
    },
    getPreloadSearchPopular () {
      const app = document.querySelector('#app')
      const popularProducts = app.dataset.searchPopular
      return popularProducts ? JSON.parse(popularProducts) : null
    },
    placeSearchResults (data, query) {
      if (data.status === 'error' && data.products_count) this.isNotFound = true
      this.query = query
      this.products = data.products
      this.count = data.products_count
      this.currentPage = data.page || 1
      this.pages = data.page_count
      this.filters = data.filters
      if (!this.count) {
        this.showPopularProducts()
      }
      if (!this.startProducts.length) {
        this.startProducts = this.products
        this.startPage = this.currentPage
      }
    },
    placePopular (data) {
      this.products = data.products
      this.currentPage = data.page
      this.pages = data.page_count
    },
    async showMore (page) {
      this.isLoadingButton = true
      const data = await getProductsCategory(this.$route.params.slug, page, this.sort, this.selectedFilters)
      this.isLoadingButton = false
      this.currentPage = page
      this.products = [...this.products, ...data.products]
      const url = this.sort ? `/search/page/${page}?sort=${this.sort}&query=${this.query}` : `/search/page/${page}?query=${this.query}`
      history.replaceState(history.state, '', url)
    },
    updatePage (path) {
      if (path === this.$route.fullPath) {
        history.replaceState(history.state, '', path)
        this.products = this.startProducts
        this.currentPage = this.startPage
      } else {
        this.startProducts = []
        this.startPage = 1
      }
    }
  },
  mounted () {
    if (this.isNeedErrorRedirect(this.$route)) return
    if (this.$route.query) this.query = this.$route.query.query
    if (this.$route.query.sort) this.sort = this.$route.query.sort
    if (this.$route.params.numberPage) this.currentPage = +this.$route.params.numberPage
    this.getPreloadSearchResults(this.$route, this.currentPage)
  },
  beforeRouteUpdate (to, from, next) {
    this.isNotFound = false
    if (this.isNeedErrorRedirect(to)) return
    if (to.query.sort) this.sort = to.query.sort
    this.currentPage = +to.params.numberPage || 1
    if (to.query.query !== from.query.query || to.query.sort !== from.query.sort) {
      this.startProducts = []
      this.startPage = 1
    }
    this.getPreloadSearchResults(to, this.currentPage)
    next()
  },
  beforeRouteLeave (to, from) {
    this.query = ''
  }
}
</script>
