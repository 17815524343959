<template lang="pug">
article.search-cap
  .search-cap__cont.cont
    h1.search-cap__caption.caption {{searchTitle}}
    p.search-cap__text.text-big(v-if="!searchInProgress" ) {{resultTextLine}}
</template>

<script>
import '@/assets/styles/components/search.sass'
import { getDeclination } from '../../assets/scripts/getDeclination'
export default {
  name: 'SearchCap',
  props: {
    query: String,
    count: Number,
    searchInProgress: Boolean,
    currentPage: Number
  },
  computed: {
    resultTextLine () {
      if (this.query) {
        if (this.count) {
          return `${getDeclination(this.count, ['Найден', 'Найдено', 'Найдено'])} ${this.count} ${getDeclination(this.count, ['результат', 'результата', 'результатов'])}`
        } else {
          return 'Ничего не найдено. Попробуйте изменить поисковый запрос'
        }
      } else {
        return 'Введите поисковый запрос'
      }
    },
    searchTitle () {
      if (this.searchInProgress) return 'Поиск...'
      return this.currentPage > 1 ? `Результаты поиска «${this.query}» - страница ${this.currentPage}` : `Результаты поиска «${this.query}»`
    }
  }
}
</script>
